import(/* webpackMode: "eager" */ "/app/app/[lang]/global.css");
import(/* webpackMode: "eager" */ "/app/components/Button/Button.module.css");
import(/* webpackMode: "eager" */ "/app/components/CountryCode/CountryCode.module.css");
import(/* webpackMode: "eager" */ "/app/components/ImageInputs/ImageInputs.tsx");
import(/* webpackMode: "eager" */ "/app/components/Info/Info.module.css");
import(/* webpackMode: "eager" */ "/app/components/MultiLevelSelect/MultiLevelSelect.tsx");
import(/* webpackMode: "eager" */ "/app/components/PasswordField/PasswordField.tsx");
import(/* webpackMode: "eager" */ "/app/components/ProfileImage/ProfileImage.tsx");
import(/* webpackMode: "eager" */ "/app/components/SimpleStatBlock/SimpleStatBlock.tsx");
import(/* webpackMode: "eager" */ "/app/components/Table/Table.tsx");
import(/* webpackMode: "eager" */ "/app/components/TagInput/TagInput.tsx");
import(/* webpackMode: "eager" */ "/app/components/TextInput/TextInput.module.css");
import(/* webpackMode: "eager" */ "/app/components/Radio/Radio.module.css");
import(/* webpackMode: "eager" */ "/app/components/Translations/Translations.tsx");
import(/* webpackMode: "eager" */ "/app/components/UserTraffic/UserTraffic.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/core/esm/components/Button/Button.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/core/esm/components/Flex/Flex.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/core/esm/components/Radio/Radio.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/core/esm/components/Select/Select.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/core/esm/components/Text/Text.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/core/esm/components/TextInput/TextInput.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/core/styles.css");
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/dates/styles.css");
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/notifications/esm/Notifications.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/notifications/esm/notifications.store.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/notifications/styles.css");
import(/* webpackMode: "eager" */ "/app/theme.ts")