'use client';

import {
  createTheme,
  CSSVariablesResolver,
  DEFAULT_THEME,
  rem,
  TextInput,
  NumberInput,
  Select,
  InputBase,
  MultiSelect,
  FileInput,
  Textarea,
} from '@mantine/core';
import { YearPickerInput, DatePickerInput, TimeInput } from '@mantine/dates';

export const theme = createTheme({
  components: {
    TextInput: TextInput.extend({
      defaultProps: {
        variant: 'filled',
      },
      styles: {
        input: {
          backgroundColor: '#F8F9FE',
        },
        label: {
          fontSize: '12px',
          fontWeight: 400
        }
      },
    }),
    Textarea: Textarea.extend({
      defaultProps: {
        variant: 'filled',
      },
      styles: {
        input: {
          backgroundColor: '#F8F9FE',
        },
        label: {
          fontSize: '12px',
          fontWeight: 400
        }
      },
    }),
    // @ts-ignore
    FileInput: FileInput.extend({
      defaultProps: {
        variant: 'filled',
      },
      styles: {
        input: {
          backgroundColor: '#F8F9FE',
        },
        label: {
          fontSize: '12px',
          fontWeight: 400
        }
      },
    }),
    DatePickerInput: DatePickerInput.extend({
      defaultProps: {
        variant: 'filled',
      },
      styles: {
        input: {
          backgroundColor: '#F8F9FE',
        },
        label: {
          fontSize: '12px',
          fontWeight: 400
        }
      },
    }),
    TimeInput: TimeInput.extend({
      defaultProps: {
        variant: 'filled',
      },
      styles: {
        input: {
          backgroundColor: '#F8F9FE',
        },
        label: {
          fontSize: '12px',
          fontWeight: 400
        }
      },
    }),
    InputBase: InputBase.extend({
      defaultProps: {
        variant: 'filled',
      },
      styles: {
        input: {
          backgroundColor: '#F8F9FE',
        },
        label: {
          fontSize: '12px',
          fontWeight: 400
        }
      },
    }),
    MultiSelect: MultiSelect.extend({
      defaultProps: {
        variant: 'filled',
      },
      styles: {
        input: {
          backgroundColor: '#F8F9FE',
        },
        label: {
          fontSize: '12px',
          fontWeight: 400
        }
      },
    }),
    NumberInput: NumberInput.extend({
      defaultProps: {
        variant: 'filled',
      },
      styles: {
        input: {
          backgroundColor: '#F8F9FE',
        },
        label: {
          fontSize: '12px',
          fontWeight: 400
        }
      },
    }),
    Select: Select.extend({
      defaultProps: {
        variant: 'filled',
      },
      styles: {
        input: {
          backgroundColor: '#F8F9FE',
        },
        label: {
          fontSize: '12px',
          fontWeight: 400
        }
      },
    }),
    YearPickerInput: YearPickerInput.extend({
      defaultProps: {
        variant: 'filled',
      },
      styles: {
        input: {
          backgroundColor: '#F8F9FE',
        },
        label: {
          fontSize: '12px',
          fontWeight: 400
        }
      },
    }),
  },
  colors: {
    ...DEFAULT_THEME.colors,
    brand: [
      '#f2f6ea',
      '#e6ecd4',
      '#d9e3bf',
      '#cdd9aa',
      '#c0d095',
      '#b3c77f',
      '#a7bd6a',
      '#9ab455',
      '#8eaa3f',
      '#81A12A',
    ],
  },
  primaryColor: 'brand',
  defaultRadius: rem(10),
  other: {
    spaces: {
      p10: '0.625rem',
      p40: '2.5rem',
      p50: '3.125rem',
    },
  },
});

export const variableOverrides: CSSVariablesResolver = () => ({
  variables: {
    '--mantine-color-gray-light-hover': 'hotpink',
    '--mantine-color-brand': '#8CB80A',
  },
  light: {
    '--mantine-color-gray-light-hover': 'hotpink',
  },
  dark: {
    '--mantine-color-gray-light-hover': 'hotpink',
  },
});
